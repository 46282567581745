export const COMMUNITY_USER_NOTE_BASE_FRAGMENT = `
  fragment communityUserNoteBaseFragment on CommunityUserNote {
    uid
    title
    content
    contentPlain
    user {
      uid
    }
    deleted
    createdTime
    updatedTime
    deletedTime
  }
`;
